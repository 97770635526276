<template>
  <div>
    <v-parallax
      height="300"
      src="https://i.ytimg.com/vi/IdMIqWnRpLg/maxresdefault.jpg"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="big-title font-weight-black">KIT</h1>
          <h4 class="subheading">Kumoh National Institute of Technology.</h4>
        </v-col>
      </v-row>
    </v-parallax>
    <v-parallax
      src="https://images.unsplash.com/photo-1536237880829-dd441c249e0d?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1280&bri=-5&con=-35"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="font-weight-black mb-4">Introduce</h1>

          <h4 class="subheading">
            안녕하십니까 벼룩시장 사이트 개발 관리를 맡은
          </h4>
          <h4 class="subheading">
            컴퓨터공학부 컴퓨터공학전공 학생회장 최재서, 컴퓨터공학과 소프트웨어전공 학생회장
            강형준입니다.
          </h4>
          <h4 class="subheading">
            좋은 기회를 주신 학생복지위원장님 그리고 많은 관심을 가져주신 학교
            선생님들과 선배님들 감사드립니다.
          </h4>
          <br />
          <h4 class="subheading">
            안녕하세요 40대 이룸 학생복지위원회 위원장 박건하입니다.
          </h4>
          <h4 class="subheading">
            앞으로도 학우분들의 복지를 위해 더 힘쓰는 학생복지위원회가
            되겠습니다.
          </h4>
          <h4 class="subheading">
            끝으로 벼룩시장 준비에 도움을 주신 컴공, 컴소공 개발자 및 학교
            선생님들께 감사의 인사드립니다.
          </h4>
        </v-col>
      </v-row>
    </v-parallax>
    <v-parallax
      src="https://images.unsplash.com/photo-1587620962725-abab7fe55159?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1280&bri=-10&con=-35"
      class="background"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="font-weight-black mb-4">2025 Developer</h1>
          <h4 class="subheading">컴퓨터공학부 컴퓨터공학전공</h4>
          <h5 class="subheading font-weight-light mt-2">관리자</h5>
          <h4 class="subheading">21' 이범희  25' 남지훈</h4>
        </v-col>
      </v-row>
    </v-parallax>
    <v-parallax
      height="200"
      src="https://i.ytimg.com/vi/IdMIqWnRpLg/maxresdefault.jpg"
    ></v-parallax>
  </div>
</template>

<script>
export default {};
</script>





<style lang="scss" scoped>
.big-title {
  font-size: 4rem;
}
.background {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
<template>
  <h1>404 NO PAGE FOUND</h1>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
